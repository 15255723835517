import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
// import SpeedDial from "@mui/material/SpeedDial";
// import SpeedDialIcon from "@mui/material/SpeedDialIcon";
// import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { fleetlist } from "../API";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {
//   Button,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import DashboardBottomBar from "../components/DashboardBottomBar";

function RobotPage(props) {
  const [selectfleet, setselectfleet] = useState("");
  const [status, setstatus] = useState("");
  const emailId = localStorage.getItem("useremail");
  const [robotlistData, setrobotlistData] = useState();
  const [copyrobotlist, setcopyrobotlist] = useState();
  const navigate = useNavigate();
  const toastmsg = (msg) => toast(msg);
  const token = localStorage.getItem("token");
  useEffect(()=>{
    if(!localStorage.getItem('token') || localStorage.getItem('token')===null ||localStorage.getItem('token')==='null'||localStorage.getItem('token')===undefined ){
        navigate('/')
      }
   },[])
 
  useEffect(() => {
    fleetlist(emailId, token)
      .then((res) => {
        setrobotlistData(res.data.fleet);
        // console.log("Robot page response", res);
      })
      .catch((err) => {
        // console.log("Robot page error", err);
      });
    setcopyrobotlist(robotlistData);
  }, []);
  const actions = [
    { icon: <FileCopyIcon />, name: "Copy" },
    { icon: <SaveIcon />, name: "Save" },
    { icon: <PrintIcon />, name: "Print" },
    { icon: <ShareIcon />, name: "Share" },
  ];
  const getrobotStatus = (status) => {
    switch (status) {
      case "AVAILABLE":
        // return <img className="FleetPage_table_status_td_img" src="/assets/images/Stop.svg"/>
        // return <p style={{marginBottom:'0px'}}> Active</p>
        return <img src="/assets/images/Line-green.svg" alt=""/>;
      case "UNAVAILABLE":
        // return <img src="/assets/images/Line 7.png"/>
        // return <p style={{marginBottom:'0px'}}> Inactive</p>
        return <img src="/assets/images/Line-red.svg" alt=""/>;
      case "ERROR":
        // return <img src="/assets/images/Line 7.png"/>
        return <p style={{ marginBottom: "0px", color: "yellow" }}> Error</p>;
      default:
        // return <img src="/assets/images/Line 7.png"/>
        return <p style={{ marginBottom: "0px", color: "red" }}>{status}</p>;
    }
  };
  const isopen = useSelector((state) => state.dashboardopenReducer);
  const naviate = useNavigate();

  const handlerobotClick = (itm) => {
    let latitude;
    let longitude;
    robotlistData.map((ttt) => {
      if (ttt.fleetId === itm.fleetId) {
        ttt.robots.map((itmmm) => {
          if (itmmm.robotId === itm.robotId) {
            latitude = itmmm.latitude;
            longitude = itmmm.longitude;
          }
        });
      }
    });
    navigate(`/robotPage/${itm.robotId}`, {
      state: {
        data: itm,
        latitude: latitude,
        longitude: longitude,
        robotlistData: robotlistData,
      },
    });
  };
  // const handleaddrobot = () => {
  //   naviate("/addrobot");
  // };

  // const handleRobotEdit = (itm) => {
  //   navigate(`/editrobot/${itm.robotId}`, { state: { data: robotlistData } });
  // };
  // const handleSelectfleet = (e) => {
  //   setselectfleet(e.target.value);
  // };
  // const handleStatusChange = (e) => {
  //   setstatus(e.target.value);
  // };

  return (
    <>
      <Header />
      <div className="Fleet_page_Whole_content_wrapper_main">
              {/* {console.log('robotlistData',robotlistData)} */}
              <DashboardSideBar
                dashboardopen={props.dashboardopen}
                active="robotpage"
              />
                <DashboardBottomBar active="robotpage" />
            </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
         
          </div>
        
          <div className="col-md-10">
            <div style={{height:'100%'}} className={isopen.userlogindata ? "" : ""}>
              <div className="Dashboard_page_rightSide_content_heading">
                <h4>Robots</h4>
              </div>
              <div className="">
                <table
                  style={{ width: "100%" }}
                  className="Fleet_page_table_Main_whole"
                >
                  <thead>
                    <tr>
                      <th> Robot Id</th>
                      <th> Fleet Id</th>
                      <th> Robot Name </th>
                      {/* <th> End Point</th>
                      <th> Date Created</th>
                      <th> SSh End Point</th> */}  
                      <th> Status</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotlistData?.map((item) => {
                      return item?.robotsDetails?.map((itm, key) => {
                        return (
                          <tr key={key}>
                            <td className="Robot_page_robotId_td">
                              {" "}
                              {itm.robotId}{" "}
                              {/* <sup
                                className={
                                  item.robots[key].connectionStatus ===
                                    "OFFLINE"
                                    ? "p-color-red-two"
                                    : "p-color-green-two"
                                }
                              >
                                {" "}
                                {item.robots[key].connectionStatus}{" "}
                              </sup>
                              {item.robots[key].connectionStatus ===
                                "OFFLINE" ? (
                                <span> {item.robots[key].lastUpdatedTime}</span>
                              ) : null} */}
                            </td>
                            <td> {item.fleetName}</td>
                            <td> {itm.displayRobotName}</td>
                            {/* <td> {itm.endpoint}</td>
                            <td> {itm.createdAt}</td>
                            <td> {itm.sshEndpoint} </td> */}
                           
                            <td className="FleetPage_table_status_td">
                              {/* {getrobotStatus(item.robots[key].robotStatus)} */}
                              <p
                                className={
                                  item?.robots[key]?.connectionStatus ===
                                    "OFFLINE"
                                    ? "p-color-red-two"
                                    : "p-color-green-two"
                                }
                              >
                                {" "}
                                {item?.robots[key]?.connectionStatus}{" "}
                              </p>
                              {item?.robots[key]?.connectionStatus ===
                                "OFFLINE" ? (
                                <span> {item.robots[key].lastUpdatedTime}</span>
                              ) : null}
                            </td>
                            <td className="FleetPage_table_action_td">
                              <img
                                className="cursor_pointer"
                                onClick={() => handlerobotClick(itm)}
                                src="/assets/images/arrow-link-icon.svg"
                                alt=""
                              />
                              {/* <img className="cursor_pointer" src="/assets/images/b.svg" onClick={()=>handleRobotEdit(itm)}/> */}
                              {/* <img
                                className="cursor_pointer"
                                src="/assets/images/Deleteiconlist.svg"
                              /> */}
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button className="FleetPage_plusIcon" onClick={handleaddrobot}>
        <AddIcon />
      </button> */}
    </>
  );
}

export default RobotPage;
