import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Maps from "../RobotActions/Maps";
import GoogleTwo from "../subcomponents/GoogleTwo";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import MapboxRobot from "../subcomponents/MapboxRobot";
import { getfleetzones, getgeofenceZone, getnogozones } from "../API";
import { useState } from "react";

function FleetPagerobotPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [geofencezone, setgeofencezone] = useState();
  const [fleetzones, setfleetzones] = useState();
  const [nogozones, setnogozones] = useState();
  const { fleetList } = useSelector(state => state.fleetList)
  const emailId = localStorage.getItem("useremail")
  const token= localStorage.getItem('token')
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const handlerobotClick = (itm) => {
    let latitude;
    let longitude;
    props?.robotlistData?.map((ttt) => {
      if (ttt.fleetId === itm.fleetId) {
        ttt.robots.map((itmmm) => {
          if (itmmm.robotId === itm.robotId) {
            latitude = itmmm.latitude;
            longitude = itmmm.longitude;
          }
        });
      }
    });

    navigate(`/robotPage/${itm.robotId}`, {
      state: {
        data: itm,
        latitude: latitude,
        longitude: longitude,
        robotlistData: fleetList[0].robots,
      },
    });
  };

  useEffect(() => {
    if (fleetList.length === 0) {
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [fleetList, dispatch, emailId])
  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined
    ) {
      window.location.replace("/");
    }

    getgeofenceZone(props?.fleetData?.fleetId, token)
      .then((res) => {
        //  console.log('Geofence Response', res)
        if(!res.data.geofence){
          setgeofencezone([])
        }
        setgeofencezone(res.data.geofence);
      })
      .catch((err) => {
        console.log("GeoFenceError", err);
      });

    getfleetzones(props?.fleetData?.fleetId, token)
      .then((res) => {
        // console.log('FleetZone Response', res)
        if(!res.data.teleoperationZones){
          setfleetzones([])
        }
        setfleetzones(res.data.teleoperationZones);
      })
      .catch((err) => {
        console.log("FleetZone errr", err);
      });
    getnogozones(props?.fleetData?.fleetId, token)
      .then((res) => {
        // console.log('NogoZone Response', res)
        if(!res.data.nogoZones){
          setnogozones([])
        }
        setnogozones(res.data.nogoZones);
      })
      .catch((err) => {
        console.log("NogoZoneERr", err);
      });
  }, [props?.fleetData?.fleetId]);
  return (
    <>
      {
        props?.fleetData?.length !== 0 && (

          <div className="Individual_fleet_page_map_wrapper">

            {/* Google Map ROBOTS OPTION */}
            {props.googlemap && geofencezone && fleetzones && nogozones ? (
              // <GoogleTwo
              //   isMarkerShown
              //   googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRQzEYlLWkb-swa-hIj5YW9F1Kz-ixg0M&v=3.exp&libraries=geometry,drawing,places"
              //   loadingElement={<div style={{ height: `100%` }} />}
              //   containerElement={<div style={{ height: "70vh" }} />}
              //   mapElement={<div style={{ height: "70vh" }} />}
              //   mapchecktwo={true}
              //   ApiKey={"AIzaSyBRQzEYlLWkb-swa-hIj5YW9F1Kz-ixg0M"}
              // />

               <MapboxRobot fleetData={props?.fleetData} geofencezone={geofencezone} fleetzones={fleetzones} nogozones={nogozones}/>
            ) : (
              <Maps
                fleetId={props?.fleetData?.fleetId}
                robotlistData={props?.fleetData?.robots}
              />
            )}
          </div>
        )}

      <div className="">
        {/* <div className="row">
          {fleetList.length !== 0 && fleetList[0].robots.map((item, index) => {
            return (
              <div className="col-md-4" key={"robot__" + index}>
                <div className="border-blue-top SelectFleetPage-inner-li-div d-flex-center">
                  <p
                    className="SelectFleetPage-inner-li-div-robot-id"
                    style={{ cursor: "pointer", color: "white" }}
                    onClick={() => handlerobotClick(item)}
                  >
                    {" "}
                    {item.robotId}
                    <sup
                      className={
                        item.connectionStatus === "OFFLINE"
                          ? "p-color-red-two"
                          : "p-color-green-two"
                      }
                    >
                      {" "}
                      {item.connectionStatus}{" "}
                    </sup>
                    {item.connectionStatus === "OFFLINE" ? (
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {item.lastUpdatedTime}
                      </span>
                    ) : null}
                  </p>

                  <div className="dashboard-left-side-div-one-inner-div">
                    <div className="battery-icon-css_three">
                      <span
                        style={{
                          width: "30px",
                          border: "1px solid #fff",
                          borderRadius: "4px",
                          padding: "2px",
                          height: "14px",
                          display: "inline-block",
                        }}
                      >
                        <strong
                          style={{
                            background:
                              parseInt(item.robotBattery).toFixed(0) <= 20
                                ? "#E4261C"
                                : "rgb(113, 207, 98)",
                            width: `${parseInt(item.robotBattery).toFixed(0)}%`,
                            display: "block",
                            height: "10px",
                          }}
                        ></strong>
                        <span>
                          {" "}
                          <p style={{ marginTop: "5px", fontSize: "13px" }}>
                            {parseInt(item.robotBattery)}%{" "}
                          </p>
                        </span>
                      </span>

                    </div>
                    <div>
                      <span
                        style={{
                          background: "#fff",
                          width: "3px",
                          height: "5px",
                          display: "inline-block",
                          borderRadius: "3px",
                          marginRight: "3px",
                          padding: "0px",
                        }}
                      ></span>
                      <span
                        style={{
                          background: "#fff",
                          width: "3px",
                          height: "10px",
                          display: "inline-block",
                          borderRadius: "3px",
                          marginRight: "3px",
                          padding: "0px",
                        }}
                      ></span>
                      <span
                        style={{
                          background: "#fff",
                          width: "3px",
                          height: "15px",
                          display: "inline-block",
                          borderRadius: "3px",
                          marginRight: "3px",
                          padding: "0px",
                        }}
                      ></span>
                      <span
                        style={{
                          background: "#fff",
                          width: "3px",
                          height: "20px",
                          display: "inline-block",
                          borderRadius: "3px",
                          marginRight: "3px",
                          opacity: "0.5",
                          padding: "0px",
                        }}
                      ></span>
                      <span
                        style={{
                          background: "#fff",
                          width: "3px",
                          height: "25px",
                          display: "inline-block",
                          borderRadius: "3px",
                          marginRight: "3px",
                          opacity: "0.5",
                          padding: "0px",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    </>
  );
}

export default FleetPagerobotPage;
