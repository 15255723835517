import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogOutModal from "./LogOutModal";

function DashboardSideBar(props) {
  const emailId = localStorage.getItem("useremail");
  const role = localStorage.getItem("role");
  const firstName=localStorage.getItem('firstname')
  const lastName=localStorage.getItem('lastname')
  const isopen = useSelector((state) => state.dashboardopenReducer);
  const [height, setheight] =useState(0)
  // const dispatch = useDispatch();
  const [responsive, setresponsive] = useState();

  useEffect(() => {
    setresponsive(window.screen.width);
    setheight(window.innerHeight-36)
    // console.log('window.screen.innerHeight',window.innerHeight)
  }, [responsive,height]);

  return (
    <>
      <div
        className={
          isopen.userlogindata || window.screen.innerWidth < 1024
            ? "Dashboard_side_bar_wrapper_main_short"
            : "Dashboard_side_bar_wrapper_main_long"
        }
        style={{height:`${height}px`}}
      >
        <ul
          className={
            isopen.userlogindata || window.screen.innerWidth < 1024
              ? "Dashboard_page_side_bar_close"
              : " Dashboard_page_side_bar"
          }
        >
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li className={props.active === "" ? "SideBar_active" : null}>
              <img
                style={{ width: "100%" }}
                src="/assets/images/OttonomyLogo.png"
                alt="logo"
              />
            </li>{" "}
          </Link>
          <Link
            to="/dashboard"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "dashboard" ? "SideBar_active" : null}
            >
              <span>
                {/* <img
                  className="Dashboard_SideBar_icon_one"
                  style={{ width: "30px", height: "30px" }}
                  src="/assets/images/Dashboard icon selected.svg"
                  alt="dashboard_icon"
                />{" "} */}
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                Dashboard
              </p>
            </li>{" "}
          </Link>
          <Link
            to="/fleetPage"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "fleetpage" ? "SideBar_active" : null}
            >
              <span style={{ width: "25%" }}>
                {/* <img
                  className="Dashboard_SideBar_icon"
                  style={{ width: "100%" }}
                  src="/assets/images/Fleeticon.svg"
                  alt="fleet_icon"
                />{" "} */}
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                {" "}
                Fleets
              </p>
            </li>
          </Link>
          <Link
            to="/robotpage"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "robotpage" ? "SideBar_active" : null}
            >
              <span style={{ width: "25%" }}>
                {/* <img
                  className="Dashboard_SideBar_icon"
                  style={{ width: "80%" }}
                  src="/assets/images/Robot_icon_sidebar.svg"
                  alt="sidebar_robot"
                /> */}
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                {" "}
                Robots
              </p>
            </li>
          </Link>
          {/* {role === "Admin" || role === "Super Admin" ? (
            <Link
              to="/userlist"
              style={{ textDecoration: "none" }}
              className="Dashboard_side_bar_links"
            >
              <li
                className={
                  props.active === "userlist" ? "SideBar_active" : null
                }
              >
                <span style={{ width: "25%" }}>
                  <img
                    className="Dashboard_SideBar_icon"
                    style={{ width: "80%" }}
                    src="/assets/images/Client_icon.svg"
                    alt="client_icon"
                  />
                </span>
                <p
                  style={{ marginBottom: "0px" }}
                  className="Dashboard_side_bar_P"
                >
                  Users
                </p>
              </li>
            </Link>
          ) : null} */}
        </ul>
        <div
          className="name-log"
       >
          {" "}
          <div>
            <p className="SideBar_name_p">{firstName} {lastName}</p>
            <p
              className="SideBar_name_p"
              style={{ opacity: "0.7", fontSize: "11px" }}
            >
              {" "}
              {role}
            </p>
            <LogOutModal />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardSideBar;
