import React from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'
import { useSelector } from 'react-redux'

function VideoStream(props) {
    const { fleetList } = useSelector(state => state.fleetList)
  return (
    <>   
     {/* <Header fleetName={true} fleetData={fleetData}/> */}

     <div className='Dashboard_page_content'> 
     <div className='Video_page_wrapper'>
     {/* {console.log('fleetList',fleetList)}
      */}
     {props?.fleetData?.robots?.map((item , index)=>{ 
        return( 
            <div key={index} className='Video_page_wrapper_div'> 
            <p className='Video_page_wrapper_p'> {item.robotId}</p>
            {/* {console.log('item?.viewerurl',item?.viewerurl)} */}
             {item?.viewerurl ? <iframe className="Video_stream_iframe_two" autoControls={true} src={`${item?.viewerurl}`} allowFullScreen></iframe> 
             : 
             <div className='No_video_available_div'> 
               <p> No video Available </p>
            </div>}
           </div>
        )
     })}
    </div>
    </div>
    </>
  )
}

export default VideoStream

